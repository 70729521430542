export const DiscountHelper = (response, props) => {
    var discountSummaryText = '';

    var monthlyDiscountText = '';

    if (response.data.monthlyDiscountGbp > 0)
    {
        if (response.data.monthlyDiscountDuration > 0)
        {
            monthlyDiscountText =  new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(response.data.monthlyDiscountGbp.toFixed(2)) + " discount for the first " + (response.data.monthlyDiscountDuration > 1 ? response.data.monthlyDiscountDuration + " months\n" : "month\n");
        }
        else
        {
            monthlyDiscountText = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(response.data.monthlyDiscountGbp.toFixed(2)) + " monthly discount\n";
        }
    }
    else if (response.data.monthlyDiscountGbp < 0)
    {
        if (response.data.monthlyDiscountDuration > 0)
        {
            monthlyDiscountText =  new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(-response.data.monthlyDiscountGbp.toFixed(2)) + " price increase for the first " + (response.data.monthlyDiscountDuration > 1 ? response.data.monthlyDiscountDuration + " months\n" : "month\n");
        }
        else
        {
            monthlyDiscountText = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(-response.data.monthlyDiscountGbp.toFixed(2)) + " monthly price increase\n";
        }
    }

    var setupDiscountText = '';
    
    if (response.data.setupDiscountGbp > 0) {
        setupDiscountText = "Up to " + new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(response.data.setupDiscountGbp.toFixed(2)) + " setup discount\n";
    }
    else if (response.data.setupDiscountGbp < 0) {
        setupDiscountText = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(-response.data.setupDiscountGbp.toFixed(2)) + " setup price increase\n";
    }

    var routerDiscountText = '';
    
    if (response.data.routerDiscountGbp > 0) {
        routerDiscountText = "Up to " + new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(response.data.routerDiscountGbp.toFixed(2)) + " router discount\n";
    }
    else if (response.data.routerDiscountGbp < 0) {
        routerDiscountText = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(-response.data.routerDiscountGbp.toFixed(2)) + " router price increase\n";
    }

    var minimumTermText = '';

    if (response.data.minimumTerm != null && response.data.minimumTerm > 0)
    {
        if (response.data.minimumTerm > props.selectedProduct.term) {
            minimumTermText = "Minimum term: " + response.data.minimumTerm + " months";
        }
        else if (response.data.minimumTerm < props.selectedProduct.term) {
            minimumTermText = "Minimum term reduced from " + props.selectedProduct.term + " to " + response.data.minimumTerm + " months";
        }
    }

    if (monthlyDiscountText !== '' || setupDiscountText !== '' || routerDiscountText !== '' || minimumTermText !== '') {
        discountSummaryText = (
            <p className='pre'>
                {monthlyDiscountText}
                {setupDiscountText}
                {routerDiscountText}
                {minimumTermText}
            </p>
        );
    }
    return discountSummaryText;
}